import React, { useState } from 'react'
import img from '../../assets/images/img-download-brochures.png'
import '../../assets/scss/components/benefits.scss'
import img01 from '../../assets/images/img-certified-01.png'
import img02 from '../../assets/images/img-certified-02.png'

const Benefits = ({ urlDownload }) => {
  const initialValues = {
    name: '',
    email: '',
    company: '',
    terms_conditions: false,
  }
  const [input, setInput] = useState(initialValues)
  const [success, setSuccess] = useState(false)

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setInput({
      ...input,
      [name]: value,
    })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const handleSubmit = event => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        ...input,
      }),
    })
      .then(() => {
        setSuccess(true)
        const link = document.createElement('a')
        link.href = urlDownload
        link.download = urlDownload
        link.dispatchEvent(new MouseEvent('click'))
      })
      .catch(error => alert(error))
  }
  return (
    <section className="benefits" data-section="#benefits">
      <div className="benefits__boxInfo">
        <figure className="benefits__wrapImg" data-drop-img="">
          <img src={img} alt="" />
        </figure>
        <div className="benefits__content">
          <ul className="benefits__list">
            <li className="benefits__item">
              <h3 className="benefits__title">Turvallisia ja käyttövalmiita</h3>
              <p className="benefits__desc">
                Tuotteissa on korvattu perinteisiä voimakkaita ja syövyttäviä
                ainesosia turvallisemmilla vaihtoehdoilla. Tarjoamme myös täyden
                pesupalvelun.{' '}
              </p>
            </li>
            <li className="benefits__item">
              <h3 className="benefits__title">Ympäristöystävällisiä</h3>
              <p className="benefits__desc">
                Turvallisuuden lisäksi olemme kiinnittäneet erityistä huomiota
                myös ympäristöystävällisyyteen ja korvanneet monia vesistöille
                ja vesieliöille pitkäaikaista haittaa aiheuttavia ainesosia
                vaarattomammilla biohajoavilla ainesosilla, jotta voisimme tehdä
                osamme vesistöjen ja vesieliöiden suojelemiseksi.{' '}
              </p>
            </li>
            <li className="benefits__item">
              <h3 className="benefits__title">
                Kansainvälisesti sertifioituja
              </h3>
              <ul className="benefits__listCertificates">
                <li className="benefits__itemCertificates">
                  <img
                    src={img01}
                    className="benefits__imgCertificates"
                    alt=""
                  />
                </li>
                <li className="benefits__itemCertificates">
                  <img
                    src={img02}
                    className="benefits__imgCertificates"
                    alt=""
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="benefits__boxForm">
        {!success ? (
          <>
            <h3 className="benefits__titleForm">Haluatko lisätietoja?</h3>
            <form
              data-netlify="true"
              name="Brochures"
              method="post"
              onSubmit={event => handleSubmit(event)}
              data-netlify-honeypot="bot-field"
              className="benefits__form"
            >
              <input type="hidden" name="form-name" value="Brochures" />
              <ul className="benefits__listForm">
                <li className="benefits__itemForm">
                  <input
                    type="text"
                    name="name"
                    id=""
                    className="benefits__input"
                    placeholder="Nimesi"
                    value={input.name}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li className="benefits__itemForm">
                  <input
                    type="email"
                    name="email"
                    id=""
                    className="benefits__input"
                    placeholder="Sähköposti"
                    value={input.email}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li className="benefits__itemForm">
                  <input
                    type="text"
                    name="company"
                    id=""
                    className="benefits__input"
                    placeholder="Yritys"
                    value={input.company}
                    onChange={handleInputChange}
                    required
                  />
                </li>
              </ul>
              <div className="benefits__wrapBtnForm">
                <input
                  type="checkbox"
                  name="terms_conditions"
                  id="terms-conditions"
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="terms-conditions">
                  Hyväksyn <a href="#">käyttöehdot</a>
                </label>
                <input
                  type="submit"
                  value="Lataa esitteitä"
                  className={`benefits__submit${
                    !input.terms_conditions ? ' is-valid' : ''
                  }`}
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <h3 className="download__heading download__heading--success">
              Kiitos! Esitteiden lataus alkaa...
              <p>Jos lataus ei ala automaattisesti, <a href="https://www.dropbox.com/sh/djd92qd2rvcnr33/AAAoHHuDfEWjFFGCv9auepLaa?dl=1" download>klikkaa tästä.</a></p>
            </h3>
          </>
        )}
      </div>
    </section>
  )
}

export default Benefits
