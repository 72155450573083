import React from 'react'
import iconMore from '../../assets/images/icon-more.svg'
import iconLess from '../../assets/images/icon-less.svg'
import '../../assets/scss/components/cursor.scss'
import img from '../../assets/images/img-banner.png'

const CustomCursor = ({ x, y, hidden, expand }) => {
  return (
    <div
      className={`cursorCustom${hidden ? ' is-hide' : ''}${
        expand ? ' is-expand' : ''
      }`}
      style={{ top: y, left: x }}
    >
      <i className="cursorCustom__icon">
        {expand ? <img src={iconLess} alt="" /> : <img src={iconMore} alt="" />}
      </i>
      <span className="cursorCustom__text">
        klikkaa {expand ? 'pienentääksesi' : 'laajentaaksesi'}
      </span>
    </div>
  )
}

export default CustomCursor
