import React, { useState } from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import gsap from 'gsap/all'
import iconClose from '../../assets/images/icon-close.svg'
import useWindowDimensions from '../../assets/utils/mediaQueryList'

const ProductItem = ({ name, number, paragraph, bgColor, index, description }) => {
  gsap.defaults({ ease: 'ease', duration: 0 })
  const [isExpand, setIsExpand] = useState(false)
  const [lockScroll, setLockScroll] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [styleContent, setStyleContent] = useState({top: '16vh'})

  const customTimeline = {}
  const mq = useWindowDimensions()
  var styleCustom = {top: 0}

  const animation = (posTop, posLeft, isReverse) => {
    const tl = gsap.timeline()
    const cloneCustom = {...customTimeline}
    if (!isExpand && !isReverse) {
      setIsLoading(true)
      tl.to(customTimeline.wrapBoxTitle, {
        zIndex: 1002,
        onComplete: () => {
          customTimeline.item.classList.add('pointer-none')
        },
      })
      .to(customTimeline.overlay, {
        zIndex: 1001,
      })
      .to(customTimeline.overlay, {
        width: '100vw',
        height: '100vh',
        top: posTop,
        left: posLeft,
        duration: 0.4,
      })
      .to(customTimeline.wrapBoxTitle, {
        position: 'absolute',
        maxWidth: mq.width >= 768 ? '259px' : 'calc(100% - 60px)',
        top: posTop + (mq.width >= 768 ? 50 : 30),
        left: posLeft + (mq.width >= 768 ? 60 : 20),
        duration: 0.5,
        onComplete: () => {
          setStyleContent({top: cloneCustom.wrapBoxTitle.offsetHeight - 40})
        }
      })
      .to(customTimeline.content, {
        autoAlpha: 1,
        duration: 0.2,
      })
      .to(customTimeline.buttonClose, {
        autoAlpha: 1,
        duration: 0.3,
        onComplete: () => {
          setIsLoading(false)
        }
      })
    }

    if (isReverse) {
      setIsLoading(true)
      tl.to(customTimeline.buttonClose, {
        autoAlpha: 0,
        duration: 0.3,
      })
        .to(customTimeline.content, {
          autoAlpha: 0,
          duration: 0.2,
        })
        .to(customTimeline.wrapBoxTitle, {
          position: 'relative',
          top: 0,
          left: 0,
          duration: 0.5,
        })
        .to(customTimeline.overlay, {
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          duration: 0.4,
        })
        .to(customTimeline.overlay, {
          zIndex: 1,
        })
        .to(customTimeline.wrapBoxTitle, {
          zIndex: 1,
          onComplete: () => {
            setIsLoading(false)
          }
        })
    }
    setIsExpand(false)
  }
  const handleClick = event => {
    if(!isLoading) {
      const rect = event.target.getBoundingClientRect()
      const { top, left } = rect
      const posTop = -top
      const posLeft = -left
      if (!isExpand) {
        animation(posTop, posLeft)
      }
      setIsExpand(true)
      setLockScroll(true)
    }
  }

  const handleClosePopup = event => {
    event.preventDefault()
    animation(0, 0, true)
    customTimeline.item.classList.remove('pointer-none')
    setLockScroll(false)
  }

  const productClass = index == 5 ? 'products__item fullwidth-item' : 'products__item';
  
  return (
    <div className={productClass} ref={e => (customTimeline['item'] = e)}>
      <div
        className={`products__overlay`}
        ref={e => (customTimeline['overlay'] = e)}
        aria-hidden="true"
        style={{ backgroundColor: bgColor }}
        onClick={handleClick}
      />
      <div
        className={`products__wrapBoxTitle`}
        ref={e => (customTimeline['wrapBoxTitle'] = e)}
      >
        <div className="products__title">
          <h3>{name}</h3>
          <span
            className={`products__number${
              index === 3 ? ' products__number--custom' : ''
            }`}
          >
            {number}
          </span>
        </div>
        <div
          className="products__paragraph"
          ref={e => (customTimeline['desc'] = e)}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      </div>
      <div
        className={`products__content`}
        ref={e => (customTimeline['content'] = e)}
      >
        <ScrollLock isActive={lockScroll} />
        <TouchScrollable>
          <div className="products__contentInner" dangerouslySetInnerHTML={{ __html: description }} />
        </TouchScrollable>
      </div>
      <a
        href=""
        className={`products__buttonClose`}
        ref={e => (customTimeline['buttonClose'] = e)}
        onClick={event => handleClosePopup(event)}
      >
        <img src={iconClose} alt="" />
      </a>
    </div>
  )
}

export default ProductItem
