import React, { useState } from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import gsap from 'gsap/all'
import img01 from '../../assets/images/img-products.png'
import img02 from '../../assets/images/img-testimonials.png'
import client01 from '../../assets/images/img-client-01.png'
import client02 from '../../assets/images/img-client-02.png'
import client03 from '../../assets/images/img-client-03.png'
import client04 from '../../assets/images/img-client-04.png'
import iconClose from '../../assets/images/icon-close.svg'
import ProductItem from './ProductItem'
import useWindowDimensions from '../../assets/utils/mediaQueryList'
import '../../assets/scss/components/products.scss'

gsap.defaults({ ease: 'ease', duration: 0 })

const Products = () => {
  const [expand, setExpand] = useState(false)
  const [lockScroll, setLockScroll] = useState(false)
  const customTimeline = {}
  const tl = gsap.timeline()
  const mq = useWindowDimensions()

  const dataItem = [
    {
      id: 2,
      name: 'ACS',
      number: '400',
      paragraph: '<p>Vähähajuinen puhdistusaine</p><p>Ruostumattoman teräksen puhdistukseen</p>',
      bgColor: '#fffaf3',
      description: '<p>Pakkauskoot: 25l, 60l, 200l, 1000l.<br>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty. </p><p>Tuote on käyttövalmis. Sitä voidaan käyttää upottamalla puhdistettavat osat ainetta sisältävään säiliöön tai vaahdottamalla ruostumattomasta teräksestä tehtyjä rakenteita. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella kemikaalia. Aine voi reagoida kontaktissa materiaalin kanssa! Älä käytä polyamidien tai ei-rautametallisten materiaalien kuten sinkin kanssa. Kuparia ja messinkiä voi aineella puhdistaa varovasti. Älä anna kemikaalin lämmetä yli 30°C ja kuparia ja messinkiä käsitellessä älä anna aineen vaikuttaa yli 20 minuuttia. Ruostumattoman teräksen kohdalla aineen voi antaa vaikuttaa kauemmin. Puhdistuskäsittelyn jälkeen huuhtele aina osia tai vesiputkistoa vedellä vähintään 15 minuuttia. <br>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy). </p><p><b>Huom:</b> pH-arvo:  < 1 = HAPAN, jäätyy yli -4°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu.  Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa korkeintaan 2 vuotta.</p>'
    },
    {
      id: 3,
      name: 'ACS',
      number: '400 OP',
      paragraph:
        '<p>Vähähajuinen, fosforihapoton peruspesuaine</p><p>Kehitetty erítyisesti herkille vedenpuhdistamoille</p>',
      bgColor: '#dee8eb',
      description: '<p>Pakkauskoot: 25l, 60l, 200l, 1000l.<br>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty.</p><p>Tuote on käyttövalmis. Sitä voidaan käyttää upottamalla puhdistettavat osat ainetta sisältävään säiliöön tai vaahdottamalla ruostumattomasta teräksestä tehtyjä rakenteita. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella kemikaalia. Aine voi reagoida kontaktissa materiaalin kanssa! Älä käytä polyamidien tai ei-rautametallisten materiaalien kuten sinkin kanssa. Kuparia ja messinkiä voi aineella puhdistaa varovasti. Älä anna kemikaalin lämmetä yli 30°C ja kuparia ja messinkiä käsitellessä älä anna aineen vaikuttaa yli 20 minuuttia. Ruostumattoman teräksen kohdalla aineen voi antaa vaikuttaa kauemmin. Puhdistuskäsittelyn jälkeen huuhtele aina osia tai vesiputkistoa vedellä vähintään 15 minuuttia. <br>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy). </p><p><b>Huom:</b> pH-arvo:  < 1 = HAPAN, jäätyy yli -4°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu. Ei sisällä fosforihappoa. Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa korkeintaan 2 vuotta.</p>',
    },
    {
      id: 1,
      name: 'ACS',
      number: '52',
      paragraph:
        '<p>Erittäin hapan puhdistusaine</p><p>Vedensuodattimien ja lämmönvaihtimien puhdistukseen</p>',
      bgColor: '#fff5dc',
      description: '<p>Pakkauskoot: 25l, 60l, 200l.<br>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty.<p><p>Tuote on käyttövalmis. Sitä voidaan käyttää vesiputkistojen pesuun tai puhdistaa kappaleet upottamalla ainetta sisältävään säiliöön. Voidaan käyttää myös vaahtona laajemmilla pinnoilla. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella kemikaalia. Aine voi reagoida kontaktissa materiaalin kanssa! Älä käytä polyamidien tai ei-rautametallisten materiaalien kuten sinkin kanssa. Kuparia ja messinkiä voi aineella puhdistaa varovasti. Älä anna kemikaalin lämmetä yli 30°C ja kuparia ja messinkiä käsitellessä älä anna aineen vaikuttaa yli 20 minuuttia. Ruostumattoman teräksen kohdalla aineen voi antaa vaikuttaa kauemmin. Puhdistuskäsittelyn jälkeen huuhtele aina huolellisesti kappaleita tai vesiputkistoa vedellä vähintään 15 minuutin ajan. <br>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy). </p><p><b>Huom:</b> pH-arvo:  < 1 = HAPAN, jäätyy yli -15°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu. Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa kork. 2 vuotta.</p>',
    },
    {
      id: 4,
      name: 'ACS',
      number: '62',
      paragraph:
        '<p>Vähähajuinen peruspesuaine, jossa kaksinkertainen määrä happoa</p><p>Vaikeasti kalkkeutuneiden osien puhdistukseen</p>',
      bgColor: '#f6faf9',
      description: '<p>Pakkauskoot: 25l, 60l, 200l. <br>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty. </p><p>Tuote on käyttövalmis. Sitä voidaan käyttää vesiputkistojen pesuun tai puhdistaa kappaleet upottamalla ainetta sisältävään säiliöön. Voidaan käyttää myös vaahtona laajemmilla pinnoilla. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella kemikaalia. Aine voi reagoida kontaktissa materiaalin kanssa! Älä käytä polyamidien tai ei-rautametallisten materiaalien kuten sinkin kanssa. Kuparia ja messinkiä voi aineella puhdistaa varovasti. Älä anna kemikaalin lämmetä yli 30°C ja kuparia ja messinkiä käsitellessä älä anna aineen vaikuttaa yli 20 minuuttia. Ruostumattoman teräksen kohdalla aineen voi antaa vaikuttaa kauemmin. Puhdistuskäsittelyn jälkeen huuhtele aina huolellisesti kappaleita tai vesiputkistoa vedellä vähintään 15 minuutin ajan. <br>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy).</p><p><b>Huom:</b> pH-arvo:  < 1 = HAPAN, jäätyy yli -15°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu. Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa kork. 2 vuotta.</p>',
    },
    {
      id: 5,
      name: 'PCS',
      number: '62',
      paragraph:
        '<p>Hapan, vaahtoamaton, vähähajuinen pesuaine erityisesti säiliöiden ja putkistojen puhdistukseen. Ei sisällä fosforihappoa.</p>',
      bgColor: '#f1eeea',
      description: '<p>Pakkauskoot: 60l, 200l ja 1000l.<br>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty. <br><br>Tuote on käyttövalmis. Sitä voidaan käyttää annostelemalla pesuaine konttipumpulla säiliöön tai putkistoon, tai upottamalla puhdistettavat osat ainetta sisältävään säiliöön. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella kemikaalia. Aine voi reagoida kontaktissa materiaalin kanssa! Älä käytä polyamidien tai ei-rautametallisten materiaalien kuten sinkin kanssa. Kuparia ja messinkiä voi aineella puhdistaa varovasti. Kuparia ja messinkiä käsitellessä älä anna aineen vaikuttaa yli 20 minuuttia. Ruostumattoman teräksen kohdalla aineen voi antaa vaikuttaa kauemmin. Puhdistuskäsittelyn jälkeen huuhtele aina osia tai vesiputkistoa vedellä vähintään 15 minuuttia. <br>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy). <br><br><b>Huom:</b>: pH-arvo:  < 1 = HAPAN, jäätyy yli -15°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu. Ei sisällä fosforihappoa. Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa korkeintaan 2 vuotta.</p>',
    },
  ]

  const animation = (posTop, posLeft, isReverse) => {
    if (!expand && !isReverse) {
      tl.to(customTimeline.wrapBoxTitle, {
        zIndex: 1002,
        onComplete: () => {
          customTimeline.item.classList.add('pointer-none')
        },
      })
        .to(customTimeline.overlay, {
          zIndex: 1001,
        })
        .to(customTimeline.overlay, {
          width: '100vw',
          height: '100vh',
          top: posTop,
          left: posLeft,
          duration: 0.4,
        })
        .to(customTimeline.paragraph, {
          autoAlpha: (mq.width >= 768 ? 1 : 0),
        })
        .to(customTimeline.wrapBoxTitle, {
          position: 'absolute',
          maxWidth: mq.width >= 768 ? '259px' : 'calc(100% - 60px)',
          flexDirection: mq.width >= 768 ? 'column' : 'row',
          alignItems: 'flex-start',
          top: posTop + (mq.width >= 768 ? 50 : 30),
          left: posLeft + (mq.width >= 768 ? 60 : 20),
          duration: 0.5,
        })
        .to(customTimeline.paragraph, {
          paddingTop: 70,
          duration: 0.5,
        })
        .to(customTimeline.content, {
          autoAlpha: 1,
          duration: 0.2,
        })
        .to(customTimeline.buttonClose, {
          autoAlpha: 1,
          duration: 0.3,
        })
    }

    if (isReverse) {
      tl.to(customTimeline.buttonClose, {
        autoAlpha: 0,
        duration: 0.3,
      })
        .to(customTimeline.content, {
          autoAlpha: 0,
          duration: 0.2,
        })
        .to(customTimeline.paragraph, {
          paddingTop: 0,
          duration: 0.2,
        })
        .to(customTimeline.wrapBoxTitle, {
          position: 'relative',
          flexDirection: 'row',
          maxWidth: '100%',
          alignItems: 'center',
          top: 0,
          left: 0,
          duration: 0.5,
        })
        .to(customTimeline.paragraph, {
          autoAlpha: 1,
        })
        .to(customTimeline.overlay, {
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          duration: 0.4,
        })
        .to(customTimeline.overlay, {
          zIndex: 1,
        })
        .to(customTimeline.wrapBoxTitle, {
          zIndex: 1,
        })
      tl.set(customTimeline.buttonClose, { clearProps: 'all' })
      tl.set(customTimeline.content, { clearProps: 'all' })
      tl.set(customTimeline.wrapBoxTitle, { clearProps: 'all' })
      tl.set(customTimeline.overlay, { clearProps: 'all' })
    }
    setExpand(false)
  }
  const handleClick = event => {
    const rect = event.target.getBoundingClientRect()
    const { top, left } = rect
    const posTop = -top
    const posLeft = -left
    if (!expand) {
      animation(posTop, posLeft)
    }
    setExpand(true)
    setLockScroll(true)
  }
  const handleClosePopup = event => {
    event.preventDefault()
    animation(0, 0, true)
    customTimeline.item.classList.remove('pointer-none')
    setLockScroll(false)
  }

  return (
    <section className="products" data-section="#products">
      <div className="products__inner">
        <div className="products__left">
          <figure className="products__image" data-drop-img="">
            <img src={img01} alt="" />
          </figure>
          <div className="products__boxWhite">
            <h3 className="products__heading">Happamat pesuaineet</h3>
            <div className="products__paragraph products__paragraph--gray">
              <p>Tavanomaiset happamat pesuaineet sisältävät ympäristölle ja ihmisille vaarallisia ainesosia. </p>
              <p>Pesuaineemme ovat huomattavasti turvallisempia ja ympäristöystävällisempiä.</p>
              <p>Edes happamat pesuaineemme eivät syövytä ihokontaktissa, kunhan roiske pestään pois 20 minuutin kuluessa.
              </p>
            </div>
          </div>
        </div>
        <div className="products__right">
          {dataItem &&
            dataItem.map(
              ({ id, name, number, paragraph, bgColor, isMobile,description }) => (
                <ProductItem
                  key={id}
                  index={id}
                  name={name}
                  number={number}
                  paragraph={paragraph}
                  description={description}
                  bgColor={bgColor}
                  isMobile={isMobile}
                />
              )
            )}
        </div>
      </div>
      <div className="products__testimonials">
        <div className="products__wrapImg">
          <figure className="products__figureTestimonial" data-drop-img="">
            <img src={img02} alt="" />
          </figure>
        </div>
        <div className="products__contentTestimonials">
          <h3 className="products__heading products__heading--margin">Asiakkaitamme</h3>
          <ul className="products__listClients">
            <li className="products__itemClient">
              <figure className="products__wrapClient">
                <img src={client01} alt="" className="products__imgClient"/>
              </figure>
            </li>
            <li className="products__itemClient">
              <figure className="products__wrapClient">
                <img src={client02} alt="" className="products__imgClient"/>
              </figure>
            </li>
            <li className="products__itemClient">
              <figure className="products__wrapClient">
                <img src={client03} alt="" className="products__imgClient"/>
              </figure>
            </li>
            <li className="products__itemClient">
              <figure className="products__wrapClient">
                <img src={client04} alt="" className="products__imgClient"/>
              </figure>
            </li>
          </ul>
        </div>
      </div>
      <div className="products__inner products__inner--centered">
        <div className="products__boxWhite">
          <h3 className="products__heading">Emäksiset pesuaineet</h3>
          <div className="products__paragraph products__paragraph--gray">
            <p>Emäksinen pesuaineemme CCS 100 on merkittävästi turvallisempi ja ympäristöystävällisempi kuin markkinoiden vastaavat tuotteet.
            </p>
          </div>
        </div>
        <div className="products__right products__right--horizontal">
          <div
            className="products__item products__item--custom"
            ref={e => (customTimeline['item'] = e)}
          >
            <div
              className="products__overlay"
              ref={e => (customTimeline['overlay'] = e)}
              aria-hidden="true"
              style={{ background: '#f1eeea' }}
              onClick={handleClick}
            />
            <div
              className="products__wrapBoxTitle products__wrapBoxTitle--horizontal"
              ref={e => (customTimeline['wrapBoxTitle'] = e)}
            >
              <div className="products__title">
                <h3>CSS</h3>
                <span className="products__number">100</span>
              </div>
              <div
                className="products__paragraph"
                ref={e => (customTimeline['paragraph'] = e)}
              >
                <p>Kaikkien öljypohjaisten ja rasvapitoisten likojen puhdistukseen muovi-, teräs-, alumiini-, betoni-, epoksipinnoilta</p>
                <p>Työpajojen, verstaiden ja  korjaamoiden lattioille ja koneille</p>
                <p>Pesukoneille ja ultraäänipesureille</p>
                <p>Vettä kestävien rakenteiden puhdistus, esimerkiksi muoviset ja metalliset ovet ja seinät rakennuksissa ja  ajoneuvoissa</p>
              </div>
            </div>

            <div
              className={`products__content`}
              ref={e => (customTimeline['content'] = e)}
            >
              <ScrollLock isActive={lockScroll} />
              <TouchScrollable>
                <div className="products__contentInner">
                  <p>Pakkauskoot:  25l, 60l, 200l, 1000l.<br/>Ainoastaan teollisuus- ja ammattikäyttöön! Kaikki asiaton käyttö kielletty.</p><p>Tuote on käyttövalmis. Sitä voidaan käyttää upottamalla puhdistettavat osat nestettä sisältävään säiliöön tai vaahdottamalla laajemmille pinnoille. Sopii käytettäväksi myös manuaaliseen puhdistukseen sienellä. Kokeile aina ennen käyttöä puhdistettavan materiaalin kestävyyttä pienellä annoksella ainetta. Aine voi reagoida kontaktissa materiaalin kanssa! Älä anna kemikaalin lämmetä yli 30°C äläkä anna sen vaikuttaa yli 20 minuuttia. Puhdistuskäsittelyn jälkeen huuhtele aina osia/pintoja tai vesiputkistoa vedellä vähintään 15 minuuttia.<br/>Jos olet epävarma puhdistusaineen turvallisesta käytöstä, ota yhteyttä Masspapiin (Masspap Oy). </p><p><b>Huom:</b> pH-arvo:  10 = emäksinen, jäätyy yli -4°C pakkasasteessa, ulkonäkö: kirkas neste, vedenomainen, väri: vaaleankeltainen, haju: erottuva, mutta heikko tuoksu.  Säilytys kuivassa, valolta suojatussa, ilmastoidussa tilassa 20°C lämpötilassa korkeintaan 2 vuotta.</p>
                </div>
              </TouchScrollable>
            </div>
            <a
              href=""
              className={`products__buttonClose`}
              ref={e => (customTimeline['buttonClose'] = e)}
              onClick={event => handleClosePopup(event)}
            >
              <img src={iconClose} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Products
