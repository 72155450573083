import React from 'react';
import Layout from "../components/Layout";
import MainVisual from "../components/MainVisual/MainVisual";
import Products from "../components/Products/Products";
import Benefits from "../components/Benefits/Benefits";
import Download from "../components/Download/Download";

const IndexPage = () => {
  const url_download = 'https://www.dropbox.com/sh/djd92qd2rvcnr33/AAAoHHuDfEWjFFGCv9auepLaa?dl=1'
  return (
    <Layout>
      <MainVisual />
      <Products />
      <Benefits urlDownload={url_download} />
      <Download urlDownload={url_download} />
    </Layout>
  );
};

export default IndexPage;
