import React, { useRef, useState } from 'react'
import CustomCursor from '../CustomCursor/CustomCursor'
import img from '../../assets/images/img-banner.png'
import '../../assets/scss/components/mainVisual.scss'

const MainVisual = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [leave, setLeave] = useState(false)
  const [expand, setExpand] = useState(false)
  const refContent = useRef(null)
  const { x, y } = position

  const handleMouseMove = event => {
    setPosition({
      x: event.clientX,
      y: event.clientY - 34,
    })
  }

  const handleMouseLeave = () => {
    setLeave(true)
  }

  const handleMouseEnter = () => {
    setLeave(false)
  }

  const handleClickMore = () => {
    const selectorRef = refContent.current
    const getHeightContentHidden = selectorRef.scrollHeight
    selectorRef.style.maxHeight = !expand ? `${getHeightContentHidden}px` : null
    setExpand(!expand)
  }
  return (
    <>
      <CustomCursor x={x} y={y} hidden={leave} expand={expand} />
      <section className="mainVisual" data-section="#about">
        <div className="mainVisual__inner">
          <div
            className="mainVisual__top"
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
            onMouseMove={event => handleMouseMove(event)}
            onClick={handleClickMore}
          >
            <a
              href="tel:+358 503 669 355"
              className="mainVisual__wrapButtonContact"
              onMouseEnter={handleMouseLeave}
              onMouseLeave={handleMouseEnter}
            >
              <span className="mainVisual__txtButtonContact">ota yhteyttä</span>
            </a>
            <div className="mainVisual__content">
              <div className="mainVisual__boxInner" ref={refContent}>
                <p>
                  Teollisten puhdistusaineiden ei tarvitse olla vaarallisia tai
                  ympäristölle haitallisia. Kaikki pesuaineemme on kehitetty
                  mahdollisimman miellyttävää, vaaratonta ja kestävää käsittelyä
                  varten. Tarjoamme täyden pesupalvelun.
                </p>
                <div>
                  Meiltä löytyvät ratkaisut esimerkiksi:
                  <ul>
                    <li>
                      Kaivoksille, terästehtaille metallipajoille ja sahoille
                    </li>
                    <li>
                      Telakoille, kuorma-autokorjaamoille, autopesuloille ja
                      rautateille{' '}
                    </li>
                    <li>Elintarviketeollisuuteen ja vedenpuhdistamoille </li>
                    <li>Muuhun valmistavaan teollisuuteen. </li>
                  </ul>
                </div>
                <p>
                  Pesuaineet ovat erittäin vähähajuisia, vedellä helposti
                  huuhdeltavia ja käyttöturvallisuudeltaan ylivertaisia muihin teollisuudessa tavallisesti käytettyihin voimakkaisiin happamiin ja emäksisiin pesuaineisiin verrattuna.
                </p>
                <p>Tuotteemme eivät sisällä VOC-yhdisteitä.</p>
              </div>
            </div>
          </div>
          <div className="mainVisual__bottom">
            <div
              className="mainVisual__figure"
              role=""
              style={{ backgroundImage: 'url(' + img + ')' }}
            ></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MainVisual
