import React, { useState } from 'react'
import logoFooter from '../../assets/images/logo-footer.svg'
import '../../assets/scss/components/download.scss'

const Download = ({urlDownload}) => {
  const initialValues = {
    name: '',
    email: '',
    company: '',
    terms_conditions: false,
  }
  const [input, setInput] = useState(initialValues)
  const [success, setSuccess] = useState(false)

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setInput({
      ...input,
      [name]: value,
    })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const handleSubmit = event => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        ...input,
      }),
    })
      .then(() => {
        setSuccess(true)
        const link = document.createElement('a')
        link.href = urlDownload
        link.download = urlDownload
        link.dispatchEvent(new MouseEvent('click'))
      })
      .catch(error => alert(error))
  }
  return (
    <section className="download" data-section="#download">
      <figure className="download__innerLogo">
        <a href="#" className="download__logo">
          <img src={logoFooter} alt="" />
        </a>
      </figure>
      <div className="download__boxCenter">
        {!success ? (
          <>
            <h3 className="download__heading">Lataa esitteitä</h3>
            <form
              data-netlify="true"
              name="Download Brochures"
              method="post"
              onSubmit={event => handleSubmit(event)}
              data-netlify-honeypot="bot-field"
            >
              <input
                type="hidden"
                name="form-name"
                value="Download Brochures"
              />
              <p className="download__paragraph">
                Etsitään yhdessä ratkaisu juuri sinun tarpeisiisi.
              </p>
              <ul className="download__listForm">
                <li className="download__itemForm">
                  <label htmlFor="name">nimesi</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="download__input"
                    value={input.name}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li className="download__itemForm">
                  <label htmlFor="email">sähköposti</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="download__input"
                    value={input.email}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li className="download__itemForm">
                  <label htmlFor="company">yrityksesi nimi</label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="download__input"
                    value={input.company}
                    onChange={handleInputChange}
                    required
                  />
                </li>
              </ul>
              <div className="download__wrapBtnForm">
                <div className="download__wrapInnerForm">
                  <input
                    type="checkbox"
                    name="terms_conditions"
                    id="terms-conditions-form2"
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="terms-conditions-form2">
                    Masspap Oy saa käyttää tietojani</label>
                </div>
                <input
                  type="submit"
                  value=""
                  className={`download__submit${
                    !input.terms_conditions ? ' is-valid' : ''
                  }`}
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <h3 className="download__heading download__heading--success">
              Kiitos! Esitteiden lataus alkaa...
            </h3>
            <p>Jos lataus ei ala automaattisesti, <a href="https://www.dropbox.com/sh/djd92qd2rvcnr33/AAAoHHuDfEWjFFGCv9auepLaa?dl=1" download>klikkaa tästä.</a></p>
          </>
        )}
      </div>
      <div className="download__boxFooter">
        <ul className="download__listSocial">
          <li className="download__itemSocial">
            <a href="https://www.linkedin.com/company/masspap/" target="_blank">
              Linkedin
            </a>
          </li>
        </ul>
        <div className="footer">
          <div className="footer__content">
            Pirkkolankatu <br />{' '}
            <span className="footer__title footer__title--yellow">
              45130 Kouvola
            </span>
            <span className="footer__title footer__title--green"> Suomi</span>
          </div>
          <ul className="footer__listContact">
            <li className="footer__itemContact">
              <a
                href="mailto:toimisto@masspap.fi"
                className="footer__linkContact"
              >
                toimisto@masspap.fi
              </a>
            </li>
            <li className="footer__itemContact">
              <a href="tel:++358503669355" className="footer__linkContact">
                +358 503 669 355
              </a>
            </li>
          </ul>
          <p className="footer__copyright">
            © 2021 Masspap Oy. Kaikki oikeudet pidätetään.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Download
